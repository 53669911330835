import { defineMessages } from "react-intl"
import metromessages from "mcommon/messages"

export const messages = defineMessages({
  is_superuser : {
    id : "muser.is_superuser",
    defaultMessage : "Is Super User"
  },
  ldap_managed : {
    id : "muser.ldap_managed",
    defaultMessage : "LDAP managed"
  },
  is_active : {
    id : "muser.is_active",
    defaultMessage : "Is Active"
  },
  is_profile : {
    id : "muser.is_profile",
    defaultMessage : "Is Profile"
  },
  change_password : {
    id : "muser.change_password",
    defaultMessage : "Change password"
  },
  change_password_page_title : {
    id : "muser.change_password_page_title",
    defaultMessage : "Change password for { username }"
  },
  users_view_title : {
    id : "muser.users_view_title",
    defaultMessage : "Users"
  },
  groups_view_title : {
    id : "muser.groups_view_title",
    defaultMessage : "Groups"
  },
  no_permission_to_configure : {
    id : "muser.no_permission_to_configure",
    defaultMessage : "Nothing to configure"
  },
  permissions_view_title : {
    id : "muser.permissions_view_title",
    defaultMessage : "Module Permissions: { value }"
  },
  error_password_mismatch : {
    id : "muser.error_password_mismatch",
    defaultMessage : "Password Mismatch"
  },
  delete_confirm : {
    id : "muser.delete_confirm",
    defaultMessage : "Are you sure to delete ?"
  },
  edit_permissions : {
    id : "muser.edit_permissions",
    defaultMessage : "edit permissions"
  },
  modules : {
    id : "muser.modules",
    defaultMessage : "Modules"
  },
  max_number_users_reached : {
    id : "muser.max_number_users_reached",
    defaultMessage : "Max Number of users reached"
  },
  edit_permissions_stations : {
    id : "muser.edit_permissions_stations",
    defaultMessage : "edit permissions stations"
  },
  stations_permissions_view_title : {
    id : "muser.stations_permissions_view_title",
    defaultMessage : "Stations Permissions: { value }"
  },
  basic : {
    id : "muser.basic",
    defaultMessage : "Basic"
  },
  advanced : {
    id : "muser.advanced",
    defaultMessage : "Advanced"
  },
  new_user : {
    id : "muser.new_user",
    defaultMessage : "New user"
  },
  new_group : {
    id : "muser.new_group",
    defaultMessage : "New group"
  },
  permissions_module : {
    id : "muser.permissions_module",
    defaultMessage : "Permissions from"
  }

})

export default { ...metromessages, ...messages }
