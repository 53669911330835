import { defineMessages } from "react-intl"

export const messages = defineMessages({
  yes : {
    id : "mreact.yes",
    defaultMessage : "Yes"
  },
  array : {
    id : "mreact.array",
    defaultMessage : "Array"
  },
  no : {
    id : "mreact.no",
    defaultMessage : "No"
  },
  default : {
    id : "mreact.default",
    defaultMessage : "Default"
  },
  session : {
    id : "mreact.session",
    defaultMessage : "work session"
  },
  next24h : {
    id : "mreact.next24h",
    defaultMessage : "next 24 hours"
  },
  next48h : {
    id : "mreact.next48h",
    defaultMessage : "next 48 hours"
  },
  week : {
    id : "mreact.week",
    defaultMessage : "Week"
  },
  last7d : {
    id : "mreact.last7d",
    defaultMessage : "Last 7 days"
  },
  last15d : {
    id : "mreact.last15d",
    defaultMessage : "Last 15 days"
  },
  help : {
    id : "mreact.help",
    defaultMessage : "Help"
  },
  component : {
    id : "mreact.component",
    defaultMessage : "Component"
  },
  componentEdit : {
    id : "mreact.componentEdit",
    defaultMessage : "Component Edition"
  },
  connectedAs : {
    id : "mreact.connectedAs",
    defaultMessage : "You are connected as "
  },
  january : {
    id : "mreact.january",
    defaultMessage : "January"
  },
  february : {
    id : "mreact.february",
    defaultMessage : "February"
  },
  march : {
    id : "mreact.march",
    defaultMessage : "March"
  },
  maxLinksNumber : {
    id : "mreact.maxLinksNumber",
    defaultMessage : "Maximum number of links reached"
  },
  april : {
    id : "mreact.april",
    defaultMessage : "April"
  },
  may : {
    id : "mreact.may",
    defaultMessage : "May"
  },
  june : {
    id : "mreact.june",
    defaultMessage : "June"
  },
  july : {
    id : "mreact.july",
    defaultMessage : "July"
  },
  august : {
    id : "mreact.august",
    defaultMessage : "August"
  },
  september : {
    id : "mreact.september",
    defaultMessage : "September"
  },
  october : {
    id : "mreact.october",
    defaultMessage : "October"
  },
  today : {
    id : "mreact.today",
    defaultMessage : "Today"
  },
  november : {
    id : "mreact.november",
    defaultMessage : "November"
  },
  december : {
    id : "mreact.december",
    defaultMessage : "December"
  },
  shortjanuary : {
    id : "mreact.shortjanuary",
    defaultMessage : "Jan"
  },
  shortfebruary : {
    id : "mreact.shortfebruary",
    defaultMessage : "Feb"
  },
  shortmarch : {
    id : "mreact.shortmarch",
    defaultMessage : "Mar"
  },
  shortapril : {
    id : "mreact.shortapril",
    defaultMessage : "Apr"
  },
  shortmay : {
    id : "mreact.shortmay",
    defaultMessage : "May"
  },
  shortjune : {
    id : "mreact.shortjune",
    defaultMessage : "Jun"
  },
  shortjuly : {
    id : "mreact.shortjuly",
    defaultMessage : "Jul"
  },
  shortaugust : {
    id : "mreact.shortaugust",
    defaultMessage : "Aug"
  },
  shortseptember : {
    id : "mreact.shortseptember",
    defaultMessage : "Sep"
  },
  shortoctober : {
    id : "mreact.shortoctober",
    defaultMessage : "Oct"
  },
  shortnovember : {
    id : "mreact.shortnovember",
    defaultMessage : "Nov"
  },
  shortdecember : {
    id : "mreact.shortdecember",
    defaultMessage : "Dec"
  },
  ok : {
    id : "mreact.ok",
    defaultMessage : "Ok"
  },
  cancel : {
    id : "mreact.cancel",
    defaultMessage : "Cancel"
  },
  select : {
    id : "mreact.select",
    defaultMessage : "Select"
  },
  search : {
    id : "mreact.search",
    defaultMessage : "Search"
  },
  refresh : {
    id : "mreact.refresh",
    defaultMessage : "Refresh"
  },
  download : {
    id : "mreact.download",
    defaultMessage : "Download"
  },
  state : {
    id : "mreact.state",
    defaultMessage : "State"
  },
  inProgress : {
    id : "mreact.inProgress",
    defaultMessage : "In progress"
  },
  formErrors : {
    id : "mreact.form_errors",
    defaultMessage : "Form contains error(s)"
  },
  render : {
    id : "mreact.render",
    defaultMessage : "Render"
  },
  notfound : {
    id : "mreact.notFound",
    defaultMessage : "Not Found"
  },
  undefined : {
    id : "mreact.undefined",
    defaultMessage : "Undefined"
  },
  back : {
    id : "mreact.back",
    defaultMessage : "Back"
  },
  loading : {
    id : "mreact.loading",
    defaultMessage : "Loading..."
  },
  loadingImageError : {
    id : "mreact.loadingImageError",
    defaultMessage : "Unable to load image"
  },
  save : {
    id : "mreact.save",
    defaultMessage : "Save"
  },
  edit : {
    id : "mreact.edit",
    defaultMessage : "Edit"
  },
  optional : {
    id : "mreact.optional",
    defaultMessage : "Optional"
  },
  options : {
    id : "mreact.options",
    defaultMessage : "Options"
  },
  submit : {
    id : "mreact.submit",
    defaultMessage : "Submit"
  },
  at : {
    id : "mreact.at",
    defaultMessage : "At"
  },
  confirm_popup_title : {
    id : "mreact.confirm_popup_title",
    defaultMessage : "Confirmation"
  },
  confirmation : {
    id : "mreact.confirmation",
    defaultMessage : "Confirmation"
  },
  confirmDeleteItem : {
    id : "mreact.confirmDeleteItem",
    defaultMessage : "Are you sure ?"
  },
  delete : {
    id : "mreact.delete",
    defaultMessage : "Delete"
  },
  duplicateItem : {
    id : "mreact.duplicateItem",
    defaultMessage : "Duplicate"
  },
  enabled : {
    id : "mreact.enabled",
    defaultMessage : "enabled"
  },
  disabled : {
    id : "mreact.disabled",
    defaultMessage : "disabled"
  },
  actions : {
    id : "mreact.actions",
    defaultMessage : "Actions"
  },
  label : {
    id : "mreact.label",
    defaultMessage : "Label"
  },
  modalLabel : {
    id : "mreact.modalLabel",
    defaultMessage : "Label"
  },
  modalLink : {
    id : "mreact.modalLink",
    defaultMessage : "Link"
  },
  clear : {
    id : "mreact.clear",
    defaultMessage : "Clear"
  },
  close : {
    id : "mreact.close",
    defaultMessage : "Close"
  },
  collapse : {
    id : "mreact.collapse",
    defaultMessage : "Collapse"
  },
  filters : {
    id : "mreact.filters",
    defaultMessage : "Filters"
  },
  undoChanges : {
    id : "mreact.cancel",
    defaultMessage : "Cancel"
  },
  confirmQuitForm : {
    id : "mreact.confirm_leave_form",
    defaultMessage : "Are you sure you want to quit this form ?"
  },
  confirmUndoChanges : {
    id : "mreact.confirm_reset_form",
    defaultMessage : "Are you sure you want to undo all changes ?"
  },
  resetFilters : {
    id : "mreact.reset_filters",
    defaultMessage : "Reset filters"
  },
  confirmResetFilters : {
    id : "mreact.confirm_reset_filters",
    defaultMessage : "Are you sure you want to reset all filters ?"
  },
  count : {
    id : "mreact.count",
    defaultMessage : "{count, number} {count, plural, one {result} other {results} }"
  },
  countProduct : {
    id : "mreact.countProduct",
    defaultMessage : "{count, number} {count, plural, one {product} other {products} }"
  },
  countExpertise : {
    id : "mreact.countExpertise",
    defaultMessage : "{count, number} {count, plural, one {expertise} other {expertises} }"
  },
  countProduction : {
    id : "mreact.countProduction",
    defaultMessage : "{count, number} {count, plural, one {production} other {productions} }"
  },
  countSubscription : {
    id : "mreact.countSubscription",
    defaultMessage : "{count, number} {count, plural, one {subscription} other {subscriptions} }"
  },
  grid : {
    id : "mreact.grid",
    defaultMessage : "Grid"
  },
  list : {
    id : "mreact.list",
    defaultMessage : "List"
  },
  backToTop : {
    id : "mreact.back_to_top",
    defaultMessage : "Back to top"
  },
  noResults : {
    id : "mreact.noResults",
    defaultMessage : "No result"
  },
  results : {
    id : "mreact.results",
    defaultMessage : "Results"
  },
  toggle : {
    id : "mreact.toggle",
    defaultMessage : "Toggle"
  },
  username : {
    id : "mreact.username",
    defaultMessage : "Username"
  },
  email : {
    id : "mreact.email",
    defaultMessage : "Email"
  },
  password : {
    id : "mreact.password",
    defaultMessage : "Password"
  },
  confirm_password : {
    id : "mreact.confirm_password",
    defaultMessage : "Confirm password"
  },
  duplicate : {
    id : "mreact.duplicate",
    defaultMessage : "Duplicate"
  },
  id : {
    id : "mreact.id",
    defaultMessage : "Id"
  },
  age : {
    id : "mreact.age",
    defaultMessage : "Age"
  },
  name : {
    id : "mreact.name",
    defaultMessage : "Name"
  },
  category : {
    id : "mreact.category",
    defaultMessage : "Category"
  },
  properties : {
    id : "mreact.properties",
    defaultMessage : "Properties"
  },
  open : {
    id : "mreact.open",
    defaultMessage : "Open"
  },
  type : {
    id : "mreact.type",
    defaultMessage : "Type"
  },
  image : {
    id : "mreact.image",
    defaultMessage : "Image"
  },
  text : {
    id : "mreact.text",
    defaultMessage : "Text"
  },
  value : {
    id : "mreact.value",
    defaultMessage : "Value"
  },
  lang : {
    id : "mreact.lang",
    defaultMessage : "Lang"
  },
  rouding : {
    id : "mreact.rounding",
    defaultMessage : "Rounding"
  },
  displayType : {
    id : "mreact.displayType",
    defaultMessage : "Display type"
  },
  preview : {
    id : "mreact.preview",
    defaultMessage : "Preview"
  },
  new : {
    id : "mreact.new",
    defaultMessage : "New"
  },
  modify : {
    id : "mreact.modify",
    defaultMessage : "Modify"
  },
  add : {
    id : "mreact.add",
    defaultMessage : "Add"
  },
  newItem : {
    id : "mreact.newItem",
    defaultMessage : "New Item"
  },
  modifyItem : {
    id : "mreact.modifyItem",
    defaultMessage : "Modify item"
  },
  addItem : {
    id : "mreact.addItem",
    defaultMessage : "Add item"
  },
  confirm : {
    id : "mreact.confirm",
    defaultMessage : "Are you sure ?"
  },
  deleteConfirm : {
    id : "mreact.confirm_delete",
    defaultMessage : "Are you sure you want to delete ?"
  },
  warning : {
    id : "mreact.warning",
    defaultMessage : "Warning"
  },
  failure : {
    id : "mreact.failure",
    defaultMessage : "Failure"
  },
  success : {
    id : "mreact.success",
    defaultMessage : "Success"
  },
  deleteSuccess : {
    id : "mreact.delete_success",
    defaultMessage : "dle successfully deleted"
  },
  deleteFailure : {
    id : "mreact.deleteFailure",
    defaultMessage : "Failed to delete item"
  },
  createSuccess : {
    id : "mreact.create_success",
    defaultMessage : "successfully created"
  },
  updateSuccess : {
    id : "mreact.update_success",
    defaultMessage : "successfully updated"
  },
  duplicateSuccess : {
    id : "mreact.duplicate_success",
    defaultMessage : "successfully duplicated"
  },
  dateFormat : {
    id : "mreact.dateFormat",
    defaultMessage : "Date format"
  },
  private : {
    id : "mreact.private",
    defaultMessage : "Private"
  },
  public : {
    id : "mreact.public",
    defaultMessage : "Public"
  },
  rename : {
    id : "mreact.rename",
    defaultMessage : "Rename"
  },
  nodata : {
    id : "mreact.nodata",
    defaultMessage : "No Data"
  },
  remove : {
    id : "mreact.remove",
    defaultMessage : "Remove"
  },
  hour : {
    id : "mreact.hour",
    defaultMessage : "Hour"
  },
  day : {
    id : "mreact.day",
    defaultMessage : "Day"
  },
  fixed : {
    id : "mreact.fixed",
    defaultMessage : "Fixed"
  },
  left : {
    id : "mreact.left",
    defaultMessage : "Left"
  },
  right : {
    id : "mreact.right",
    defaultMessage : "Right"
  },
  top : {
    id : "mreact.top",
    defaultMessage : "Top"
  },
  bottom : {
    id : "mreact.bottom",
    defaultMessage : "Bottom"
  },
  description : {
    id : "mreact.description",
    defaultMessage : "Description"
  },
  color : {
    id : "mreact.color",
    defaultMessage : "Color"
  },
  custom : {
    id : "mreact.custom",
    defaultMessage : "Custom"
  },
  predefined : {
    id : "mreact.predefined",
    defaultMessage : "predefined"
  },
  size : {
    id : "mreact.size",
    defaultMessage : "Size"
  },
  auto : {
    id : "mreact.auto",
    defaultMessage : "Auto"
  },
  width : {
    id : "mreact.width",
    defaultMessage : "Width"
  },
  height : {
    id : "mreact.height",
    defaultMessage : "Height"
  },
  utc : {
    id : "mreact.utc",
    defaultMessage : "UTC"
  },
  loc : {
    id : "mreact.loc",
    defaultMessage : "loc"
  },
  hours : {
    id : "mreact.hours",
    defaultMessage : "hours"
  },
  information : {
    id : "mreact.information",
    defaultMessage : "Information"
  },
  date : {
    id : "mreact.date",
    defaultMessage : "Date"
  },
  country : {
    id : "mreact.country",
    defaultMessage : "Country"
  },
  parent : {
    id : "mreact.parent",
    defaultMessage : "Parent"
  },
  extranet : {
    id : "mreact.extranet",
    defaultMessage : "Extranet"
  },
  home : {
    id : "mreact.home",
    defaultMessage : "home"
  },
  item : {
    id : "mreact.item",
    defaultMessage : "Item"
  },
  crud : {
    id : "mreact.crud",
    defaultMessage : "Crud"
  },
  crudEdition : {
    id : "mreact.crudEdition",
    defaultMessage : "Item edition"
  },
  protocol : {
    id : "mreact.protocol",
    defaultMessage : "Protocol"
  },
  parameters : {
    id : "mreact.parameters",
    defaultMessage : "Parameters"
  },
  parameter : {
    id : "mreact.parameter",
    defaultMessage : "Parameter"
  },
  catalog : {
    id : "mreact.catalog",
    defaultMessage : "Catalog"
  },
  undo : {
    id : "mreact.undo",
    defaultMessage : "Undo"
  },
  redo : {
    id : "mreact.redo",
    defaultMessage : "Redo"
  },
  create_new_date_format : {
    id : "mreact.create_new_date_format",
    defaultMessage : "Select or type new format to create to create it"
  },
  invalid_date_format : {
    id : "mreact.invalid_date_format",
    defaultMessage : "Invalid Date Format"
  },
  select_create_format : {
    id : "mreact.select_create_format",
    defaultMessage : `Select a format or type in the desired date format ISO string.
Following your format with a pipe let you select the desired languages (ex: dddd:fr).`
  },
  invalidColor : {
    id : "mreact.invalidColor",
    defaultMessage : "Invalid hexadecimal code color"
  },
  invalidRegex : {
    id : "mreact.invalidRegex",
    defaultMessage : "Input must match regex {regex}"
  },
  logout : {
    id : "mreact.logout",
    defaultMessage : "Log out"
  },
  logout_confirm : {
    id : "mreact.logout_confirm",
    defaultMessage : "This will log you out, are you sure you want to continue ?"
  },
  placeholderDate : {
    id : "mreact.placeholderDate",
    defaultMessage : "Date"
  },
  startDate : {
    id : "mreact.startDate",
    defaultMessage : "Start date"
  },
  endDate : {
    id : "mreact.endDate",
    defaultMessage : "End date"
  },
  unlimited : {
    id : "mreact.unlimited",
    defaultMessage : "Unlimited"
  },
  import : {
    id : "mreact.import",
    defaultMessage : "Import"
  },
  errorRequired : {
    id : "mreact.error_required",
    defaultMessage : "Please enter a value"
  },
  errorMinValue : {
    id : "mreact.error_min_value",
    defaultMessage : "Must be greater than or equal to {min}"
  },
  errorMaxValue : {
    id : "mreact.error_max_value",
    defaultMessage : "Must be lower than or equal to {max}"
  },
  errorMinStrictValue : {
    id : "mreact.error_strict_min_value",
    defaultMessage : "Must be greater than {min}"
  },
  errorMaxStrictValue : {
    id : "mreact.error_strict_max_value",
    defaultMessage : "Must be lower than {max}"
  },
  errorMinLength : {
    id : "mreact.error_min_length",
    defaultMessage : "Must be longer than or equal to {min} characters"
  },
  errorMaxLength : {
    id : "mreact.error_max_length",
    defaultMessage : "Must be shorter than or equal to {max} characters"
  },
  emailInvalid : {
    id : "mreact.email_invalid",
    defaultMessage : "Invalid e-mail"
  },
  slugInvalid : {
    id : "mreact.slug_invalid",
    defaultMessage : "Invalid Value: Please use only numbers,letters or _ . -"
  },
  instanceInvalid : {
    id : "mreact.instance_invalid",
    defaultMessage : "Invalid instance ({ value } instead of { constructor })"
  },
  typeInvalid : {
    id : "mreact.type_invalid",
    defaultMessage : "Invalid type ({ value } instead of { typeName })"
  },
  choiceInvalid : {
    id : "mreact.choice_invalid",
    defaultMessage : "Invalid choice { value } : must be one of { values }"
  },
  idInvalid : {
    id : "mreact.id_invalid",
    defaultMessage : "Invalid ID"
  },
  dateInvalid : {
    id : "mreact.date_invalid",
    defaultMessage : "Invalid date"
  },
  errorAlphaNum : {
    id : "mreact.error_alphanum",
    defaultMessage : "Invalid Value: Please use only numbers,letters or _ . - { additionalChars }"
  },
  rememberMe : {
    id : "mreact.rememberMe",
    defaultMessage : "Remember me"
  },
  logMe : {
    id : "mreact.logMe",
    defaultMessage : "Log in"
  },
  world : {
    id : "mreact.world",
    defaultMessage : "world"
  },
  continent : {
    id : "mreact.continent",
    defaultMessage : "continent"
  },
  city : {
    id : "mreact.city",
    defaultMessage : "city"
  },
  district : {
    id : "mreact.district",
    defaultMessage : "district"
  },
  street : {
    id : "mreact.street",
    defaultMessage : "street"
  },
  noResultsText : {
    id : "mreact.noResultsText",
    defaultMessage : "No results found"
  },
  placeholderSelect : {
    id : "mreact.placeholderSelect",
    defaultMessage : "Select ..."
  },
  placeholderMultiSelect : {
    id : "mreact.placeholderMultiSelect",
    defaultMessage : "Select one or many ..."
  },
  placeholderMultiSelectFem : {
    id : "mreact.placeholderMultiSelectFem",
    defaultMessage : "Select one or many ...."
  },
  placeholderSelectCreatable : {
    id : "mreact.placeholderSelectCreatable",
    defaultMessage : "Select or create ..."
  },
  placeholderMultiSelectCreatable : {
    id : "mreact.placeholderMultiSelectCreatable",
    defaultMessage : "Select one or many or create ..."
  },
  createValue : {
    id : "mreact.createValue",
    defaultMessage : "Create : {value}"
  },
  accessError : {
    id : "mreact.accessError",
    defaultMessage : "Missing Authorization to view the page."
  },
  thisIsAtooltip : {
    id : "mreact.thisIsAtooltip",
    defaultMessage : "This is a translated tooltip"
  },
  translatedLabel : {
    id : "mreact.translatedLabel",
    defaultMessage : "translated label"
  },
  fonts : {
    id : "mreact.fonts",
    defaultMessage : "Fonts"
  },
  fontImport : {
    id : "mreact.fontImport",
    defaultMessage : "Font Import"
  },
  url : {
    id : "mreact.url",
    defaultMessage : "Url"
  },
  EnableImportMessage : {
    id : "mreact.EnableImportMessage",
    defaultMessage : "Select a customer to enable the font import button"
  },
  none : {
    id : "mreact.none",
    defaultMessage : "None"
  },
  dotted : {
    id : "mreact.dotted",
    defaultMessage : "dotted"
  },
  dashed : {
    id : "mreact.dashed",
    defaultMessage : "dashed"
  },
  solid : {
    id : "mreact.solid",
    defaultMessage : "solid"
  },
  double : {
    id : "mreact.double",
    defaultMessage : "double"
  },
  groove : {
    id : "mreact.groove",
    defaultMessage : "groove"
  },
  ridge : {
    id : "mreact.ridge",
    defaultMessage : "ridge"
  },
  inset : {
    id : "mreact.inset",
    defaultMessage : "inset"
  },
  outset : {
    id : "mreact.outset",
    defaultMessage : "outset"
  },
  dmsUnit : {
    id : "mreact.dmsUnit",
    defaultMessage : "Degrees Minutes Seconds"
  },
  decimalDegreeUnit : {
    id : "mreact.decimalDegreeUnit",
    defaultMessage : "Decimal Degrees"
  },
  filter_by_group : {
    id : "mreact.filter_by_group",
    defaultMessage : "Filter by group"
  },
  CatalogOfInterestPoints : {
    id : "mreact.CatalogOfInterestPoints",
    defaultMessage : "Catalog of interest points"
  },
  NewPoint : {
    id : "mreact.NewPoint",
    defaultMessage : "New Point"
  },
  ZoneType : {
    id : "mreact.zoneType",
    defaultMessage : "Zone type"
  },
  GeoJsonFileImport : {
    id : "mreact.GeoJsonFileImport",
    defaultMessage : "Import a GEO-JSON file"
  },
  Point : {
    id : "mreact.point",
    defaultMessage : "Point"
  },
  LineString : {
    id : "mreact.lineString",
    defaultMessage : "LineString"
  },
  Polygon : {
    id : "mreact.polygon",
    defaultMessage : "Polygon"
  },
  MultiPolygon : {
    id : "mreact.multipolygon",
    defaultMessage : "Multiple polygons"
  },
  associatedCustomer : {
    id : "mreact.associatedCustomer",
    defaultMessage : "Associated Customer"
  },
  customer : {
    id : "mreact.customer",
    defaultMessage : "Customer"
  },
  initFilter : {
    id : "mreact.initFilter",
    defaultMessage : "Init filter"
  },
  status : {
    id : "mreact.status",
    defaultMessage : "Status"
  },
  selectAll : {
    id : "mreact.selectAll",
    defaultMessage : "Select All"
  },
  deSelectAll : {
    id : "mreact.deSelectAll",
    defaultMessage : "Deselect All"
  },
  pageNotFound : {
    id : "mreact.pageNotFound",
    defaultMessage : "Page not found"
  },
  alreadyExistName : {
    id : "mreact.alreadyExistName",
    defaultMessage : "Field name already exist !"
  },
  above : {
    id : "mreact.above",
    defaultMessage : "Above"
  },
  below : {
    id : "mreact.below",
    defaultMessage : "Below"
  },
  before : {
    id : "mreact.before",
    defaultMessage : "Before"
  },
  after : {
    id : "mreact.after",
    defaultMessage : "After"
  },
  now : {
    id : "mreact.now",
    defaultMessage : "Now"
  },
  confirmRemove : {
    id : "mreact.confirmRemove",
    defaultMessage : "Are you sure you want to remove this link ?"
  },
  validate : {
    id : "mreact.validate",
    defaultMessage : "Validate"
  },
  keepRatio : {
    id : "mreact.keepRatio",
    defaultMessage : "Keep ratio"
  },
  selectImagePart : {
    id : "mreact.selectImagePart",
    defaultMessage : "Select part of the image"
  },
  resetImage : {
    id : "mreact.resetImage",
    defaultMessage : "Reset original image"
  },
  timezone : {
    id : "mreact.timezone",
    defaultMessage : "Time zone"
  },
  heridux : {
    id : "mreact.heridux",
    defaultMessage : "Heridux"
  },
  group : {
    id : "mreact.group",
    defaultMessage : "Group"
  },
  yesterday : {
    id : "mreact.yesterday",
    defaultMessage : "Yesterday"
  },
  tomorrow : {
    id : "mreact.tomorrow",
    defaultMessage : "Tomorrow"
  },
  sevenLastDays : {
    id : "mreact.sevenLastDays",
    defaultMessage : "Seven last days"
  },
  expandedSession : {
    id : "mreact.expandedSession",
    defaultMessage : "Expanded session"
  },
  config : {
    id : "mreact.config",
    defaultMessage : "Config"
  },
  advancedOptions : {
    id : "mreact.advancedOptions",
    defaultMessage : "Advanced Options"
  },
  managePersonalWebLinks : {
    id : "mreact.managePersonalWebLinks",
    defaultMessage : "Management of web links in the banner (specific to your user account only)"
  },
  expandLeftMenuInfo : {
    id : "mreact.expandLeftMenuInfo",
    defaultMessage : "Opening of the left banner to explain the names of the proposed items"
  },
  closeLeftMenuInfo : {
    id : "mreact.closeLeftMenuInfo",
    defaultMessage : "Fold the left banner"
  },
  allValues : {
    id : "mreact.allValues",
    defaultMessage : "All values"
  },
  dataMonitoring : {
    id : "mreact.dataMonitoring",
    defaultMessage : "Data monitoring"
  },
  dataMonitoringConfig : {
    id : "mreact.dataMonitoringConfig",
    defaultMessage : "Data monitoring configuration"
  },
  dataMonitoringModule : {
    id : "mreact.dataMonitoringModule",
    defaultMessage : "Data monitoring module"
  },
  newDataMonitoring : {
    id : "mreact.newDataMonitoring",
    defaultMessage : "New data monitoring"
  },
  activeDatamonitoring : {
    id : "mreact.activeDatamonitoring",
    defaultMessage : "Active"
  },
  monitorEvery : {
    id : "mreact.monitorEvery",
    defaultMessage : "Monitor every"
  },
  generateMonitoringEvent : {
    id : "mreact.generateMonitoringEvent",
    defaultMessage : "Generate monitoring event"
  },
  generateEndMonitoringEvent : {
    id : "mreact.generateEndMonitoringEvent",
    defaultMessage : "Generate end of monitoring event"
  },
  ifNochange : {
    id : "mreact.ifNoChange",
    defaultMessage : "If no change, trigger follow-up event after"
  },
  ifEventEnded : {
    id : "mreact.ifEventEnded",
    defaultMessage : "If the event ended after"
  },
  timeLimit : {
    id : "mreact.timeLimit",
    defaultMessage : "Time limit"
  },
  triggerConditions : {
    id : "mreact.triggerConditions",
    defaultMessage : "Trigger conditions"
  },
  daysLimitation : {
    id : "mreact.daysLimitation",
    defaultMessage : "Days"
  },
  periodLimitation : {
    id : "mreact.periodLimitation",
    defaultMessage : "Period"
  },
  enabledConditions : {
    id : "mreact.enabledConditions",
    defaultMessage : "Enabled condition(s)"
  },
  initByPreviousState : {
    id : "mreact.initByPreviousState",
    // eslint-disable-next-line max-len
    defaultMessage : "Initialization of the previous state at the beginning of the monitoring period\nAt the beginning of the period you cannot \"compare\" with the previous state\nWe can initialise here (which will not trigger any production). If expert data (the expert date will be used as the date of the event if the conditions are met)"
  },
  minutes : {
    id : "mreact.minutes",
    defaultMessage : "minutes"
  },
  immediately : {
    id : "mreact.immediately",
    defaultMessage : "Immediately"
  },
  monday : {
    id : "mreact.monday",
    defaultMessage : "Monday"
  },
  tuesday : {
    id : "mreact.tuesday",
    defaultMessage : "Tuesday"
  },
  wednesday : {
    id : "mreact.wednesday",
    defaultMessage : "Wednesday"
  },
  thursday : {
    id : "mreact.thursday",
    defaultMessage : "Thursday"
  },
  friday : {
    id : "mreact.friday",
    defaultMessage : "Friday"
  },
  saturday : {
    id : "mreact.saturday",
    defaultMessage : "Saturday"
  },
  sunday : {
    id : "mreact.sunday",
    defaultMessage : "Sunday"
  },
  shortmonday : {
    id : "mreact.shortmonday",
    defaultMessage : "Mon"
  },
  shorttuesday : {
    id : "mreact.shorttuesday",
    defaultMessage : "Tues"
  },
  shortwednesday : {
    id : "mreact.shortwednesday",
    defaultMessage : "Wed"
  },
  shortthursday : {
    id : "mreact.shortthursday",
    defaultMessage : "Thurs"
  },
  shortfriday : {
    id : "mreact.shortfriday",
    defaultMessage : "Fri"
  },
  shortsaturday : {
    id : "mreact.shortsaturday",
    defaultMessage : "Sat"
  },
  shortsunday : {
    id : "mreact.shortsunday",
    defaultMessage : "Sun"
  },
  fitToWidth : {
    id : "mreact.fitToWidth",
    defaultMessage : "Fit to width"
  },
  fitToHeight : {
    id : "mreact.fitToHeight",
    defaultMessage : "Fit to height"
  },
  zoomIn : {
    id : "mreact.zoomIn",
    defaultMessage : "Zoom in"
  },
  zoomOut : {
    id : "mreact.zoomOut",
    defaultMessage : "Zoom out"
  },
  filterByCustomer : {
    id : "mreact.filterByCustomer",
    defaultMessage : "Filter by customer"
  },
  fullscreen : {
    id : "mreact.fullscreen",
    defaultMessage : "Fullscreen"
  },
  empty : {
    id : "mreact.empty",
    defaultMessage : "Empty"
  },
  duplicateFailure : {
    id : "mreact.duplicateFailure",
    defaultMessage : "Failed to duplicate item"
  },
  superUser : {
    id : "mreact.superUser",
    defaultMessage : "Super user"
  },
  title : {
    id : "mreact.title",
    defaultMessage : "Title"
  },
  importError : {
    id : "mreact.importError",
    defaultMessage : "An error occurred during import"
  },
  selectPlaceholder : {
    id : "mreact.selectPlaceholder",
    defaultMessage : "Select..."
  },
  selectedValues : {
    id : "mreact.selectedValues",
    defaultMessage : "Selected values"
  },
  continue : {
    id : "mreact.continue",
    defaultMessage : "Continue"
  },
  previous : {
    id : "mreact.previous",
    defaultMessage : "Previous"
  },
  next : {
    id : "mreact.next",
    defaultMessage : "Next"
  },
  operationAborted : {
    id : "mreact.operationAborted",
    defaultMessage : "The Operation was aborted"
  },
  error : {
    id : "mreact.error",
    defaultMessage : "Error"
  }
})

export default messages
